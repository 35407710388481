<template>
  <vx-card class="cust-export-btninvite">
    <div class="">
      <vs-button @click="fetchExcelData">Export</vs-button>
    </div>
    <vs-prompt
      title="Export To Excel"
      class="export-options"
      @cancle="clearFields"
      @accept="exportToExcel"
      accept-text="Export"
      @close="clearFields"
      :active.sync="activePrompt"
    >
      <vs-input
        v-model="fileName"
        placeholder="Enter File Name.."
        class="w-full"
      />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";

export default {
  props: {
    excelData: {
      type: Array,
      default: () => []
    },
    isGetFullData: {
      type: Boolean
    }
  },
  components: {
    vSelect
  },
  data() {
    return {
      fileName: "",
      formats: ["xlsx", "csv", "txt"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: [],
      headerVal: [],
      users: [],
      activePrompt: false
    };
  },
  methods: {
    exportToExcel() {
      console.log(this.selectedFormat);
      if(this.selectedFormat==null) {
        this.$vs.notify({
          // title: "Login Attempt",
          text: "Select Format",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });
        return null
      }
      if(this.fileName.trim()=='') {
        this.$vs.notify({
          // title: "Login Attempt",
          text: "Select FileName",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });
        return null
      }
      import("@/Core/vendor/Export2Excel").then(excel => {
        console.log(this.excelData);
        const list = this.excelData;
        this.headerTitle = Object.keys(this.excelData[0]);
        const data = this.formatJson(Object.keys(this.excelData[0]), list);
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        });
        this.clearFields();
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          // Add col name which needs to be translated
          // if (j === 'timestamp') {
          //   return parseTime(v[j])
          // } else {
          //   return v[j]
          // }

          return v[j];
        })
      );
    },
    clearFields() {
      (this.fileName = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    async fetchExcelData() {
      if (this.isGetFullData) {
        this.$emit("fetchData");
        this.$nextTick(() => {
          // will run after $emit is done
          this.activePrompt = true;
        });
      } else {
        this.activePrompt = true;
      }
    }
  }
};
</script>
