<template>
  <div class="lg:m-12 md:m-12 m-4" style="height: 100vh">
    <div class="lg:p-4 md:p-4 py-4 flex gap-4">
      <!-- <div class="w-1/4">
        <select class="form-select w-full sellect" aria-label="Default select example">
          <option selected>Open the select</option>
          <option value="1">1</option>
          <option value="2">2</option>
        </select>
      </div> -->

      <div class="w-full relative">
        <vs-input
          type="search"
          icon-after
          @keyup="getcategory()"
          v-model="search"
          placeholder="Search"
          class="w-full"
        >
        </vs-input>
        <!-- <div class="absolute right18 top6">
          <feather-icon icon="SearchIcon" />
        </div> -->
      </div>
    </div>
    <div class="lg:p-4 md:p-4 py-4 flex justify-between">
      <h1 class="fs-24px">Manage Category</h1>
      <vs-button
        class="lg:p-4 md:p-4 px-4 py-2"
        icon="icon-plus"
        icon-pack="feather"
        shadow
        @click="sidebarRight = true"
      >
        Add New Category
      </vs-button>
      <b-sidebar
        title="Add Category"
        class="bg-ec1f30 text-black"
        right
        shadow
        v-model="sidebarRight"
      >
        <div class="px-4 py-8">
          <div>
            <div class="center">
              <p class="fsize16 text-black">Name</p>
              <div class="mt-2">
                <input
                  v-model="AddCategory"
                  class="w-full inp"
                  placeholder="Select"
                />
              </div>
            </div>
          </div>
          <div class="mt-20">
            <vs-button class="w-full" @click="add_category()">
              SUBMIT
            </vs-button>
          </div>
        </div>
      </b-sidebar>
      <b-sidebar
        id="sidebar-edit"
        title="Edit Category"
        class="bg-ec1f30 text-black"
        right
        shadow
        v-model="editRight"
      >
        <div class="px-4 py-8">
          <div>
            <div class="center">
              <p class="fsize16 text-black">Name</p>
              <div class="mt-2">
                <input
                  v-model="EditCategory"
                  class="w-full selecct"
                  placeholder="Select"
                />
              </div>
            </div>
          </div>
          <div class="mt-20">
            <vs-button class="w-full" @click="submiteditcategory()">
              SUBMIT
            </vs-button>
          </div>
        </div>
      </b-sidebar>
    </div>
    <div class="lg:p-4 md:p-4">
      <div>
        <vs-table max-items="5" pagination :data="category">
          <template slot="thead" class="w-full">
            <vs-th> Name </vs-th>
            <vs-th class="w-1/6"> Actions </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr v-for="(item, key) in data" :key="key">
              <vs-td>
                <span>{{ item.name }}</span>
              </vs-td>

              <vs-td>
                <div class="examplex">
                  <vs-dropdown :color="colorx">
                    <a class="a-icon" href="#">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        class="cursor-pointer text-black"
                      />
                    </a>

                    <vs-dropdown-menu>
                      <vs-dropdown-item>
                        <div
                          class="flex items-center gap-4"
                          @click="Edit_category(item)"
                        >
                          <feather-icon
                            icon="Edit2Icon"
                            class="w-4 h-4 cursor-pointer"
                            shadow
                          />
                          Edit
                        </div>
                      </vs-dropdown-item>
                      <vs-dropdown-item>
                        <div
                          class="flex items-center gap-4"
                          @click="confirmdeleted(item)"
                        >
                          <feather-icon
                            icon="DeleteIcon"
                            class="w-4 h-4 cursor-pointer"
                          />
                          Delete
                        </div>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </vs-td>
            </vs-tr>
            <!-- <vs-tr>
              <vs-td>
                <span>Sachin</span>
              </vs-td>

              <vs-td>
                <div class="examplex">
                  <vs-dropdown :color="colorx">
                    <a class="a-icon" href="#">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        class="cursor-pointer text-black"
                      />
                    </a>

                    <vs-dropdown-menu>
                      <vs-dropdown-item>
                        <div class="flex items-center gap-4">
                          <feather-icon
                            icon="Edit2Icon"
                            class="w-4 h-4 cursor-pointer"
                          />
                          Edit
                        </div>
                      </vs-dropdown-item>
                      <vs-dropdown-item>
                        <div class="flex items-center gap-4">
                          <feather-icon
                            icon="DeleteIcon"
                            class="w-4 h-4 cursor-pointer"
                          />
                          Delete
                        </div>
                      </vs-dropdown-item>
                      <vs-dropdown-item>
                        <div class="flex items-center gap-4">
                          <feather-icon
                            icon="EyeIcon"
                            class="w-4 h-4 cursor-pointer"
                          />
                          View associate bags
                        </div>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td>
                <span>Sachin</span>
              </vs-td>

              <vs-td>
                <div class="examplex">
                  <vs-dropdown :color="colorx">
                    <a class="a-icon" href="#">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        class="cursor-pointer text-black"
                      />
                    </a>

                    <vs-dropdown-menu>
                      <vs-dropdown-item>
                        <div class="flex items-center gap-4">
                          <feather-icon
                            icon="Edit2Icon"
                            class="w-4 h-4 cursor-pointer"
                          />
                          Edit
                        </div>
                      </vs-dropdown-item>
                      <vs-dropdown-item>
                        <div class="flex items-center gap-4">
                          <feather-icon
                            icon="DeleteIcon"
                            class="w-4 h-4 cursor-pointer"
                          />
                          Delete
                        </div>
                      </vs-dropdown-item>
                      <vs-dropdown-item>
                        <div class="flex items-center gap-4">
                          <feather-icon
                            icon="EyeIcon"
                            class="w-4 h-4 cursor-pointer"
                          />
                          View associate bags
                        </div>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </vs-td>
            </vs-tr> -->
          </template>
        </vs-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const postsRepository = RepositoryFactory.get("posts");
export default {
  data: () => ({
    users: [],
    value: "",
    search: "",
    AddCategory: "",
    EditCategory: "",
    Edit_id: "",
    sidebarRight: false,
    editRight: false,
  }),
  computed: {
    ...mapState("post", ["category"]),
  },
  methods: {
    async add_category() {
      if (this.AddCategory == "") {
        return this.$swal("", "Category cannot be empty", "error");
      }
      const regex = /^[a-zA-Z0-9 ]*$/;
      const result = this.AddCategory.match(regex);
      if (result == null) {
        return this.$swal("", "Please Enter Valid Category", "error");
      }
      if (this.AddCategory == "") return;
      try {
        //  await postsRepository.KotakgaddCategory({
        //   name: this.AddCategory,
        // });
        let data = await this.$store.dispatch("post/KotakgaddCategory", {
          name: this.AddCategory,
        });
        if (data.status == 200) {
          this.getcategory();
          this.AddCategory = "";
          this.sidebarRight = false;
        }
      } catch (err) {
        // console.log(err);
      }
    },
    async submiteditcategory() {
      if (this.EditCategory == "") return;
      const payload = {
        id: this.Edit_id,
        name: this.EditCategory,
      };
      try {
        // await postsRepository.KotakgeditCategory(payload);
        let data = await this.$store.dispatch(
          "post/KotakgeditCategory",
          payload
        );
        if (data.status == 200) {
          this.EditCategory = "";
          this.getcategory();
          this.editRight = false;
        }
      } catch (err) {
        // console.log(err);
      }
    },
    async Edit_category(item) {
      // console.log(item);
      // if (this.EditCategory == "") return;
      this.editRight = true;
      this.EditCategory = item.name;
      this.Edit_id = item._id;
      // try {
      //   const data = await postsRepository.KotakgaddCategory({
      //     name: this.AddCategory,
      //   });

      //   // if(data.code==200){
      //   // this.openSliderCategory=false
      //   this.getcategory();
      //   this.AddCategory = "";
      //   // }
      // } catch (err) {
      //   console.log(err);
      // }
    },
    async getcategory() {
      const payload = {};
      if (this.search) {
        Object.assign(payload, { search: this.search });
      }
      // const { data } = await postsRepository.KotakgetCategory();
      await this.$store.dispatch("post/KotakgetCategory", payload);
      // console.log(this.category);
      // if(data.status==200){
      // console.log(data);

      this.users.push(data);
      // console.log(this.users);
      // }
    },
    async confirmdeleted(item) {
      this.$swal({
        title: "Are you sure?",
        text: `Category will be discarded`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.deletecategory(item._id);
        }
      });
    },
    async deletecategory(id) {
      const payload = {
        id: id,
      };
      const data = await this.$store.dispatch(
        "post/KotakdeleteCategory",
        payload
      );
      // const data = await postsRepository.KotakdeleteCategory(payload);
      if (data.status == 200) {
        this.getcategory();
      }
    },
  },
  mounted() {
    this.getcategory();
  },
};
</script>

<style>
.top6 {
  top: 6px !important;
}
.right18 {
  right: 18px !important;
}

span.input-span-placeholder.vs-input--placeholder.normal {
  font-size: 14px;
  padding-top: 10px;
  padding-left: 20px;
}
.vs-dropdown--menu--after,
.vs-dropdown-right--menu--after {
  display: none !important;
}
.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  z-index: 200;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
.sellect {
  padding: 10px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  color: rgba(0, 0, 0, 0.4);
  height: 40px;
  font-family: "Montserrat";
}
.input {
  height: 30px;
  border-radius: 5px;
  padding: 0 10px;
  color: gray;
  font-size: 12px;
  background: #f1f3f9 !important;
  border: none;
  font-family: "Montserrat";
}

.b-sidebar-body {
  background: #fff !important;
}
.b-sidebar.b-sidebar-right {
  width: 25% !important;
}
.strong#sidebar-right___title__ {
  color: black;
}
.bg-ec1f30 .b-sidebar-header {
  background: #ec1f30 !important;
  color: #fff;
}
.b-sidebar > .b-sidebar-header {
  display: flex;
  flex-direction: row-reverse;
}
.b-sidebar.b-sidebar-right > .b-sidebar-header .close {
  margin-left: auto !important;
  margin-right: 0 !important;
}

@media (max-width: 641px) {
  .b-sidebar.b-sidebar-right {
    width: 75% !important;
  }
}
</style>
