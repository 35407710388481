<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-list">
    <ExportExcel
      :excelData="excelData"
      :isGetFullData="true"
      @fetchData="getFullData"
    ></ExportExcel>
    <div class="vx-card lg:p-6 p-2">
      <div class="lg:flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  usersData.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : usersData.length
                }}
                of {{ usersData.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <div class="lg:flex">
          <div
            class="h-12 p-4 lg:my-0 md:my-0 my-2 lg:mx-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            v-for="day in days"
            :key="'day-' + day"
            @click="getDateWiseData(day)"
          >
            <span class="lg:mr-2">Last {{ day }} days</span>
          </div>
        </div>
        <div class="date_grp items-center">
          <datepicker
            placeholder="From Date"
            v-model="fromDate"
            class="lg:w-1/4 lg:m-4 datepicker_class"
          ></datepicker>
          <datepicker
            placeholder="To Date"
            v-model="toDate"
            class="lg:w-1/4 lg:m-4 datepicker_class"
            :disabledDates="disabledDates"
          ></datepicker>
          <div class="">
            <vs-button
              color="primary"
              type="filled"
              class="px-4 lg:py-3 py-2 smallh"
              @click="runReport"
              >Run Report</vs-button
            >
          </div>
        </div>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="usersData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
      >
      </ag-grid-vue>

      <vs-pagination :total="totalPages" :max="7" v-model="currentPage" />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/Client/Kotak/scss/agGridStyleOverride.scss";
import vSelect from "vue-select";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const promotionsRepository = RepositoryFactory.get("promotions");
import ExportExcel from "@/Core/Views/CommonPlugins/export-excel/ExportExcel";

// Cell Renderer
import CellRendererLink from "./cell-renderer/CellRendererLink.vue";
import CellRendererStatus from "./cell-renderer/CellRendererStatus.vue";
import CellRendererVerified from "./cell-renderer/CellRendererVerified.vue";
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";
import Datepicker from "vuejs-datepicker";

import calendarMixin from "@/Client/Kotak/mixins/calendarMixin";

export default {
  mixins: [calendarMixin],
  components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    ExportExcel,
    Datepicker,
  },
  data() {
    return {
      // Filter Options
      roleFilter: { label: "All", value: "all" },
      roleOptions: [
        { label: "All", value: "all" },
        { label: "Admin", value: "admin" },
        { label: "User", value: "user" },
        { label: "Staff", value: "staff" },
      ],

      statusFilter: { label: "All", value: "all" },
      statusOptions: [
        { label: "All", value: "all" },
        { label: "Active", value: "active" },
        { label: "Deactivated", value: "deactivated" },
        { label: "Blocked", value: "blocked" },
      ],

      isVerifiedFilter: { label: "All", value: "all" },
      isVerifiedOptions: [
        { label: "All", value: "all" },
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],

      departmentFilter: { label: "All", value: "all" },
      departmentOptions: [
        { label: "All", value: "all" },
        { label: "Sales", value: "sales" },
        { label: "Development", value: "development" },
        { label: "Management", value: "management" },
      ],

      searchQuery: "",

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "Article Name",
          field: "text",
          width: 300,
          filter: true,
          valueGetter: (params) => {
            return  (
              this.converttoplian(params.data.text) ||
              this.converttoplian(params.data.imagetext) ||
              this.converttoplian(params.data.videotext) ||
              this.converttoplian(params.data.audiotext) ||
              this.converttoplian(params.data.blogtext)
            );
          },
        },
        {
          headerName: "No. of Recipients",
          field: "email",
          filter: true,
          valueGetter: (params) => {
            return params.data.GroupMembers ? params.data.GroupMembers : 0;
          },
          width: 180,
          cellClass: "text-center",
        },
        {
          headerName: "No. of Opens",
          field: "ArticlesViewed",
          filter: true,
          valueGetter: (params) => {
            return params.data.ArticlesViewed ? params.data.ArticlesViewed : 0;
          },
          width: 150,
          cellClass: "text-center",
        },
        {
          headerName: "No. of Forwards",
          field: "ArticlesShared",
          filter: true,
          valueGetter: (params) => {
            return params.data.ArticlesShared ? params.data.ArticlesShared : 0;
          },
          width: 180,
          cellClass: "text-center",
        },
        {
          headerName: "No. of Likes",
          field: "ArticlesLiked",
          filter: true,
          valueGetter: (params) => {
            return params.data.ArticlesLiked ? params.data.ArticlesLiked : 0;
          },
          width: 150,
          cellClass: "text-center",
        },
        {
          headerName: "No. of Comments",
          field: "ArticlesCommented",
          filter: true,
          valueGetter: (params) => {
            return params.data.ArticlesCommented
              ? params.data.ArticlesCommented
              : 0;
          },
          width: 180,
          cellClass: "text-center",
        },
        {
          headerName: "Article Date",
          field: "post_created_datetimestamp",
          filter: true,
          valueGetter: (params) => {
            return new Date(
              params.data.post_created_datetimestamp
            ).toLocaleString("en-GB");
          },
          width: 250,
          cellClass: "text-center",
        },
      ],

      tableData: [],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions,
      },
      fromDate: "",
      toDate: "",
      days: [7, 30, 90],
    };
  },
  watch: {
    roleFilter(obj) {
      this.setColumnFilter("role", obj.value);
    },
    statusFilter(obj) {
      this.setColumnFilter("status", obj.value);
    },
    isVerifiedFilter(obj) {
      const val =
        obj.value === "all" ? "all" : obj.value === "yes" ? "true" : "false";
      this.setColumnFilter("is_verified", val);
    },
    departmentFilter(obj) {
      this.setColumnFilter("department", obj.value);
    },
  },
  computed: {

    usersData() {
      return this.tableData;
    },
    excelData() {
      return this.tableData.map((x) => {
        return {
          "Article Name":
            x.text || x.imagetext || x.videotext || x.audiotext || x.blogtext,
          "No. of Recipients": x.GroupMembers ? x.GroupMembers : 0,
          "No. of Opens": x.ArticlesViewed ? x.ArticlesViewed : 0,
          "No. of Forwards": x.ArticlesShared ? x.ArticlesShared : 0,
          "No. of Likes": x.ArticlesLiked ? x.ArticlesLiked : 0,
          "No. of Comments": x.ArticlesCommented ? x.ArticlesCommented : 0,
          "Article Date": new Date(x.post_created_datetimestamp).toLocaleString(
            "en-GB"
          ),
        };
      });
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
    disabledDates() {
      const today = new Date();
      // const tomorrow = new Date(today);
      // // tomorrow.setDate(tomorrow.getDate() + 1);
      const disabledDates = {
        from: today,
      };
      return disabledDates;
    },
  },
  methods: {
    converttoplian(html){
      var template = document.createElement('div')
      template.innerHTML=html
      return template.textContent || template.innerText || ''
    },
    runReport() {
      this.getData();
    },
    async getData() {
      const from = new Date(this.fromDate.setHours(0, 0, 0));
      let to = new Date(this.toDate.setHours(23, 59, 0));
      const formattedTo = to.toISOString();

      let payload = {
        communityName: currentCommunityName.communityName,
        fromDate:
          this.fromDate == ""
            ? "2000-01-01 00:00:00"
            : this.getCurrentDate(from).replace("T", " ").replace("Z", ""),
        toDate:
          this.toDate == ""
            ? "2050-01-01 00:00:00"
            : formattedTo.replace("T", " ").replace("Z", ""),
      };
      try {
        this.$vs.loading();
        const { data } = await promotionsRepository.getArticleWiseData(payload);
        this.$vs.loading.close();
        if (data) {
          this.tableData = data.data;
        }
      } catch (error) {
        if (error) this.$vs.loading.close();
      }
    },
    setDates(days) {
      const now = new Date();
      this.toDate = new Date();
      this.fromDate = new Date(now.setDate(now.getDate() - days));
      return new Promise((resolve) => resolve(true));
    },
    async getDateWiseData(days) {
      const dates = await this.setDates(days);
      if (dates) {
        this.runReport();
      }
    },
    async getFullData() {
      this.$vs.loading();
      let payload = {
        communityName: currentCommunityName.communityName,
        fromDate: "2000-01-01 00:00:00",
        toDate: "2050-01-01 00:00:00",
      };
      const { data } = await promotionsRepository.getArticleWiseData(payload);
      if (data) {
        this.$vs.loading.close();
        this.tableData = data.data;
      }
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== "all") {
        modelObj = { type: "equals", filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null);
      this.gridApi.onFilterChanged();

      // Reset Filter Options
      this.roleFilter =
        this.statusFilter =
        this.isVerifiedFilter =
        this.departmentFilter =
          {
            label: "All",
            value: "all",
          };

      this.$refs.filterCard.removeRefreshAnimation();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
  },
  mounted() {
    // this.getData();
    this.gridApi = this.gridOptions.api;

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left = `-${String(
        Number(header.style.transform.slice(11, -3)) + 9
      )}px`;
    }
  },
  created() {
    // if (!moduleUserManagement.isRegistered) {
    //   this.$store.registerModule("userManagement", moduleUserManagement);
    //   moduleUserManagement.isRegistered = true;
    // }
    // this.$store.dispatch("userManagement/fetchUsers").catch(err => {
    //   console.error(err);
    // });
  },
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
