<template>
  <div class="m-12" style="height: 100vh">
    <div class="p-4 flex gap-4">
      <div class="w-full relative">
        <vs-input
          type="search"
          icon-after
          v-model="search"
          placeholder="Search"
          class="w-full"
          @keyup="getcommunitygroup()"
        >
        </vs-input>
        <div class="absolute right18 top6">
          <feather-icon icon="SearchIcon" />
        </div>
      </div>
    </div>
    <div class="p-4 flex justify-between">
      <h1>Channel Management</h1>
    </div>
    <div class="p-4">
      <div>
      <vs-table max-items="5" pagination :data="group">
        <template slot="thead" class="w-full">
          <!-- <vs-tr> -->
            <vs-th> Channel name </vs-th>
            <vs-th class="w-1/6"> Actions </vs-th>
          <!-- </vs-tr> -->
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="i" v-for="(tr, i) in data">
            <vs-td>
              <span>{{ tr.group_name }}</span>
            </vs-td>

            <vs-td>
              <div class="examplex">
              <feather-icon
                icon="TrashIcon"
                class="w-5 h-5 cursor-pointer"
                @click="showPostTypeSectionAlert(tr)"
              />
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    </div>
  </div>
</template>

<script>
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const groupsRepository = RepositoryFactory.get("groups");
export default {
  data: () => ({
    users: [
      {
        id: 1,
        name: "Kotak channel",
      },
      {
        id: 2,
        name: "Kotak channel",
      },
    ],
    group: {},
    token: null,
  }),
  methods: {
    showPostTypeSectionAlert(item) {
      if (this.blogPost && this.showTextEditor) {
        this.showTextEditor = false;
      } else {
        this.$swal({
          text: `Are you sure? you want to delete?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            this.deletecommunitygroup(item);
          }
        });
      }
    },
    async getcommunitygroup() {
      const payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      if (this.search != null) {
        Object.assign(payload.communityData, { Search: this.search });
      }
      const { data } = await groupsRepository.getCommunityGroup(payload);
      // if (data.ResponseCode == 100) {
      this.group = data;
      // }
    },
    async deletecommunitygroup(item) {
      const payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        status: 1,
        name: this.token.username,
        groupId: item.group_UniqueId,
      };
      const { data } = await groupsRepository.deleteGroup(payload);
      // if (data.ResponseCode == 100) {
      this.getcommunitygroup();
      // }
    },
  },
  mounted() {
    this.getcommunitygroup();
    let newtoken = localStorage.getItem("token");
    this.token = jwt.decode(localStorage.getItem("token"));
    let token = jwt.decode(newtoken);
  },
};
</script>
<style>
.top6 {
  top: 6px !important;
}

.right18 {
  right: 18px !important;
}
.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  z-index: 200;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
</style>
