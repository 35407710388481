<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-list">
    <ExportExcel :excelData="excelData"></ExportExcel>

    <div class="vx-card lg:p-6 p-2">
      <div class="lg:flex flex-wrap items-center">
        <div class="flex-grow"></div>
        <div class="lg:flex">
          <div
            class="h-12 p-4 lg:my-0 md:my-0 my-2 lg:mx-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            v-for="day in days"
            :key="'day-' + day"
            @click="getDateWiseData(day)"
          >
            <span class="mr-2">Last {{ day }} days</span>
          </div>
        </div>
        <div class="date_grp items-center lg:my-0 md:my-0 my-2">
          <datepicker
            placeholder="From Date"
            v-model="fromDate"
            class="lg:w-1/4 lg:m-4 datepicker_class"
          ></datepicker>
          <datepicker
            placeholder="To Date"
            v-model="toDate"
            class="lg:w-1/4 lg:m-4 datepicker_class"
            :disabledDates="disabledDates"
          ></datepicker>
          <div>
            <vs-button
              color="primary"
              class="px-4 lg:py-3 py-2 smallh"
              type="filled"
              @click="runReport"
              >Run Report</vs-button
            >
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div
          class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-based cursor-pointer"
          :class="{ stat_active: showTable == card.statisticTitle }"
          v-for="(card, index) in cards"
          :key="`stat-${index}`"
          @click="handleClick(card)"
        >
          <statistics-card-line
            hideChart
            class="bor"
            :icon="card.icon"
            icon-right
            :statistic="card.statistic"
            :statisticTitle="card.statisticTitle"
          />
        </div>
      </div>
    </div>
    <InvitedUsers
      :fromDate="fromDate"
      :toDate="toDate"
      v-show="showTable && showTable == 'Invited Users added'"
      ref="invitedUsersComponent"
    />
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/Client/Kotak/scss/agGridStyleOverride.scss";
import vSelect from "vue-select";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const promotionsRepository = RepositoryFactory.get("promotions");
import ExportExcel from "@/Core/Views/CommonPlugins/export-excel/ExportExcel";

// Cell Renderer
import CellRendererLink from "./cell-renderer/CellRendererLink.vue";
import CellRendererStatus from "./cell-renderer/CellRendererStatus.vue";
import CellRendererVerified from "./cell-renderer/CellRendererVerified.vue";
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";

import Datepicker from "vuejs-datepicker";
import StatisticsCardLine from "./StatisticsCardLine.vue";
import InvitedUsers from "./Time Range/InvitedUsers";

import calendarMixin from "@/Client/Kotak/mixins/calendarMixin";

export default {
  mixins: [calendarMixin],
  components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    ExportExcel,
    Datepicker,
    StatisticsCardLine,
    InvitedUsers,
  },
  data() {
    return {
      // Filter Options
      roleFilter: { label: "All", value: "all" },
      roleOptions: [
        { label: "All", value: "all" },
        { label: "Admin", value: "admin" },
        { label: "User", value: "user" },
        { label: "Staff", value: "staff" },
      ],

      statusFilter: { label: "All", value: "all" },
      statusOptions: [
        { label: "All", value: "all" },
        { label: "Active", value: "active" },
        { label: "Deactivated", value: "deactivated" },
        { label: "Blocked", value: "blocked" },
      ],

      isVerifiedFilter: { label: "All", value: "all" },
      isVerifiedOptions: [
        { label: "All", value: "all" },
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],

      departmentFilter: { label: "All", value: "all" },
      departmentOptions: [
        { label: "All", value: "all" },
        { label: "Sales", value: "sales" },
        { label: "Development", value: "development" },
        { label: "Management", value: "management" },
      ],

      searchQuery: "",

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "No. of Dormant Receivers",
          field: "DormantCount",
          width: 250,
          valueGetter: (params) => {
            return params.data.DormantCount ? params.data.DormantCount : 0;
          },
          filter: true,
          cellClass: "text-center",
        },
        {
          headerName: "No. of Users added",
          field: "GroupMembers",
          filter: true,
          valueGetter: (params) => {
            return params.data.GroupMembers ? params.data.GroupMembers : 0;
          },
          width: 200,
          cellClass: "text-center",
        },
        {
          headerName: "No. of Forwards",
          field: "ArticlesShared",
          filter: true,
          valueGetter: (params) => {
            return params.data.ArticlesShared ? params.data.ArticlesShared : 0;
          },
          width: 180,
          cellClass: "text-center",
        },
        {
          headerName: "No. of Likes",
          field: "ArticlesLiked",
          filter: true,
          valueGetter: (params) => {
            return params.data.ArticlesLiked ? params.data.ArticlesLiked : 0;
          },
          width: 150,
          cellClass: "text-center",
        },
        {
          headerName: "No. of Comments",
          field: "ArticlesCommented",
          filter: true,
          valueGetter: (params) => {
            return params.data.ArticlesCommented
              ? params.data.ArticlesCommented
              : 0;
          },
          width: 180,
          cellClass: "text-center",
        },
        {
          headerName: "List of Articles shared",
          field: "text",
          filter: true,
          width: 250,
          cellClass: "text-center",
        },
      ],
      tableData: [],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions,
      },
      fromDate: "",
      toDate: "",
      showTable: "",
      days: [7, 30, 90],
    };
  },
  watch: {
    roleFilter(obj) {
      this.setColumnFilter("role", obj.value);
    },
    statusFilter(obj) {
      this.setColumnFilter("status", obj.value);
    },
    isVerifiedFilter(obj) {
      const val =
        obj.value === "all" ? "all" : obj.value === "yes" ? "true" : "false";
      this.setColumnFilter("is_verified", val);
    },
    departmentFilter(obj) {
      this.setColumnFilter("department", obj.value);
    },
  },
  computed: {
    cards() {
      return [
        {
          icon: "UserPlusIcon",
          statistic: this.totalInvitedUsers,
          statisticTitle: "Invited Users added",
        },
        {
          icon: "UsersIcon",
          statistic: this.totalUsers,
          statisticTitle: "Users added",
        },
        {
          icon: "BookOpenIcon",
          statistic: this.totalReads,
          statisticTitle: "Reads",
        },
        {
          icon: "SendIcon",
          statistic: this.totalForwards,
          statisticTitle: "Forwards",
        },
        {
          icon: "HeartIcon",
          statistic: this.totalLikes,
          statisticTitle: "Likes",
        },
        {
          icon: "MessageSquareIcon",
          statistic: this.totalComments,
          statisticTitle: "Comments",
        },
        {
          icon: "CalendarIcon",
          statistic: this.totalFXViews,
          statisticTitle: "FX Views",
        },
      ];
    },
    totalInvitedUsers() {
      return this.tableData[0] && this.tableData[0].length
        ? this.tableData[0]
            .map((x) => x.InvitedUserCount)
            .reduce((accumulator, currentValue) => accumulator + currentValue)
        : 0;
    },
    totalUsers() {
      return this.tableData[1] && this.tableData[1].length
        ? this.tableData[1]
            .map((x) => x.UserCount)
            .reduce((accumulator, currentValue) => accumulator + currentValue)
        : 0;
    },
    totalReads() {
      return this.tableData[2] && this.tableData[2].length
        ? this.tableData[2]
            .map((x) => x.ReadCount)
            .reduce((accumulator, currentValue) => accumulator + currentValue)
        : 0;
    },
    totalForwards() {
      return this.tableData[3] && this.tableData[3].length
        ? this.tableData[3]
            .map((x) => x.ForwardCount)
            .reduce((accumulator, currentValue) => accumulator + currentValue)
        : 0;
    },
    totalLikes() {
      return this.tableData[4] && this.tableData[4].length
        ? this.tableData[4]
            .map((x) => x.LikeCount)
            .reduce((accumulator, currentValue) => accumulator + currentValue)
        : 0;
    },
    totalComments() {
      return this.tableData[5] && this.tableData[5].length
        ? this.tableData[5]
            .map((x) => x.CommentCount)
            .reduce((accumulator, currentValue) => accumulator + currentValue)
        : 0;
    },
    totalFXViews() {
      return this.tableData[6] && this.tableData[6].length
        ? this.tableData[6]
            .map((x) => x.FXCalendarReadCount)
            .reduce((accumulator, currentValue) => accumulator + currentValue)
        : 0;
    },
    excelData() {
      // return [
      //   ...this.tableData[0],
      //   ...this.tableData[1],
      //   ...this.tableData[2],
      //   ...this.tableData[3],
      //   ...this.tableData[4],
      // ];
      return [
        {"Invited Users added":this.totalInvitedUsers,
        "Users added":this.totalUsers,
        "Reads":this.totalReads,
        "Forwards":this.totalForwards,
        "Likes":this.totalLikes,
        "Comments":this.totalComments,
        "FX Views":this.totalFXViews},
      ]
    },
    usersData() {
      return this.tableData[1] && this.tableData[1].length
        ? [
            {
              name: "Users Added",
              data: this.tableData[1].map((x) => x.UserCount),
            },
          ]
        : [{ name: "Users Added", data: [] }];
    },
    forwardsData() {
      return this.tableData[3] && this.tableData[3].length
        ? [
            {
              name: "Forwards",
              data: this.tableData[3].map((x) => x.ForwardCount),
            },
          ]
        : [{ name: "Forwards", data: [] }];
    },
    likesData() {
      return this.tableData[4] && this.tableData[4].length
        ? [
            {
              name: "Likes",
              data: this.tableData[4].map((x) => x.LikeCount),
            },
          ]
        : [{ data: [] }];
    },
    commentsData() {
      return this.tableData[5] && this.tableData[5].length
        ? [
            {
              name: "Comments",
              data: this.tableData[5].map((x) => x.CommentCount),
            },
          ]
        : [{ data: [] }];
    },

    disabledDates() {
      const today = new Date();
      // const tomorrow = new Date(today);
      // // tomorrow.setDate(tomorrow.getDate() + 1);
      const disabledDates = {
        from: today,
      };
      return disabledDates;
    },
  },
  methods: {
    runReport() {
      this.getData();
      if (this.showTable == "Invited Users added") {
        this.handleInvitedUsers();
      }
    },
    async getData() {
      const from = new Date(this.fromDate.setHours(0, 0, 0));
      let to = new Date(this.toDate.setHours(23, 59, 0));
      const formattedTo = to.toISOString();

      let payload = {
        communityName: currentCommunityName.communityName,
        fromDate:
          this.fromDate == ""
            ? "2000-01-01 00:00:00"
            : this.getCurrentDate(from).replace("T", " ").replace("Z", ""),
        toDate:
          this.toDate == ""
            ? "2050-01-01 00:00:00"
            : formattedTo.replace("T", " ").replace("Z", ""),
      };
      try {
        this.$vs.loading();

        const { data } = await promotionsRepository.getTimeRangeData(payload);
        this.$vs.loading.close();
        if (data) {
          this.tableData = data.data;
        }
      } catch (error) {
        if (error) this.$vs.loading.close();
      }
    },
    setDates(days) {
      const now = new Date();
      this.toDate = new Date();
      this.fromDate = new Date(now.setDate(now.getDate() - days));
      return new Promise((resolve) => resolve(true));
    },
    async getDateWiseData(days) {
      const dates = await this.setDates(days);
      if (dates) {
        this.runReport();
      }
    },
    handleClick(card) {
      if (card.statisticTitle == "Invited Users added") {
        this.showTable = card.statisticTitle;
        this.handleInvitedUsers();
      } else {
        this.showTable = "";
      }
    },
    handleInvitedUsers() {
      this.$refs.invitedUsersComponent.getData();
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== "all") {
        modelObj = { type: "equals", filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null);
      this.gridApi.onFilterChanged();

      // Reset Filter Options
      this.roleFilter =
        this.statusFilter =
        this.isVerifiedFilter =
        this.departmentFilter =
          {
            label: "All",
            value: "all",
          };

      this.$refs.filterCard.removeRefreshAnimation();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
  },
  mounted() {
    this.getData();
    this.gridApi = this.gridOptions.api;

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left = `-${String(
        Number(header.style.transform.slice(11, -3)) + 9
      )}px`;
    }
  },
  created() {
    // if (!moduleUserManagement.isRegistered) {
    //   this.$store.registerModule("userManagement", moduleUserManagement);
    //   moduleUserManagement.isRegistered = true;
    // }
    // this.$store.dispatch("userManagement/fetchUsers").catch(err => {
    //   console.error(err);
    // });
  },
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
.bor {
  border: 2px solid #ececec !important;
}
.mb-based {
  margin-top: 5px !important;
  margin-bottom: 19px !important;
}
@media (max-width: 640px) {
  .mb-based {
    margin-bottom: 10px !important;
  }
}
</style>
