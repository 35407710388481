<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-list">
    <!-- <vx-card
      ref="filterCard"
      title="Filters"
      class="user-list-filters mb-8"
      actionButtons
      @refresh="resetColFilters"
      @remove="resetColFilters"
    >
      <div class="vx-row m-5">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Role</label>
          <v-select
            :options="roleOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="roleFilter"
            class="mb-4 md:mb-0"
          />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Status</label>
          <v-select
            :options="statusOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="statusFilter"
            class="mb-4 md:mb-0"
          />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Verified</label>
          <v-select
            :options="isVerifiedOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="isVerifiedFilter"
            class="mb-4 sm:mb-0"
          />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Department</label>
          <v-select
            :options="departmentOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="departmentFilter"
          />
        </div>
      </div>
    </vx-card> -->

    <ExportExcel :excelData="excelData"></ExportExcel>

    <div class="vx-card p-6 padding-b">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  usersData.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : usersData.length
                }}
                of {{ usersData.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="usersData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
      >
      </ag-grid-vue>

      <vs-pagination :total="totalPages" :max="7" v-model="currentPage" />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/Client/Kotak/scss/agGridStyleOverride.scss";
import vSelect from "vue-select";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const promotionsRepository = RepositoryFactory.get("promotions");
import ExportExcel from "@/Core/Views/CommonPlugins/export-excel/ExportExcel";

// Cell Renderer
import CellRendererLink from "./cell-renderer/CellRendererLink.vue";
import CellRendererStatus from "./cell-renderer/CellRendererStatus.vue";
import CellRendererVerified from "./cell-renderer/CellRendererVerified.vue";
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";

export default {
  components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    ExportExcel,
  },
  data() {
    return {
      // Filter Options
      roleFilter: { label: "All", value: "all" },
      roleOptions: [
        { label: "All", value: "all" },
        { label: "Admin", value: "admin" },
        { label: "User", value: "user" },
        { label: "Staff", value: "staff" },
      ],

      statusFilter: { label: "All", value: "all" },
      statusOptions: [
        { label: "All", value: "all" },
        { label: "Active", value: "active" },
        { label: "Deactivated", value: "deactivated" },
        { label: "Blocked", value: "blocked" },
      ],

      isVerifiedFilter: { label: "All", value: "all" },
      isVerifiedOptions: [
        { label: "All", value: "all" },
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],

      departmentFilter: { label: "All", value: "all" },
      departmentOptions: [
        { label: "All", value: "all" },
        { label: "Sales", value: "sales" },
        { label: "Development", value: "development" },
        { label: "Management", value: "management" },
      ],

      searchQuery: "",

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "Client Name",
          field: "usersDetails_UserName",
          width: 300,
          filter: true,
        },
        {
          headerName: "Phone",
          field: "usersDetails_Mobile",
          width: 150,
          filter: true,
        },
        {
          headerName: "Email",
          field: "usersDetails_Email",
          width: 200,
          filter: true,
        },
        {
          headerName: "CRN",
          field: "CRN",
          width: 200,
          filter: true,
          valueGetter: (params) => {
            return params.data.CRN ? params.data.CRN : null;
          },
        },
        {
          headerName: "Kotak Customer",
          field: "kotakCustomer",
          width: 200,
          filter: true,
          valueGetter: (params) => {
            return params.data.kotakCustomer ? params.data.kotakCustomer : "No";
          },
        },
        {
          headerName: "Company Name",
          field: "companyName",
          width: 200,
          filter: true,
          valueGetter: (params) => {
            return params.data.companyName ? params.data.companyName : null;
          },
        },
        {
          headerName: "Whatsapp Update",
          field: "whatsappContent",
          width: 200,
          filter: true,
          valueGetter: (params) => {
            return params.data.whatsappContent
              ? params.data.whatsappContent
              : "No";
          },
        },
        {
          headerName: "No. of articles sent",
          field: "ArticlesSent",
          filter: true,
          width: 200,
          valueGetter: (params) => {
            return params.data.ArticlesSent ? params.data.ArticlesSent : 0;
          },
          cellClass: "text-center",
        },
        {
          headerName: "No. of articles Read",
          field: "ArticlesViewed",
          filter: true,
          width: 200,
          valueGetter: (params) => {
            return params.data.ArticlesViewed ? params.data.ArticlesViewed : 0;
          },
          cellClass: "text-center",
        },
        {
          headerName: "Total Time Spent",
          field: "TimeSpent",
          filter: true,
          width: 200,
          valueGetter: (params) => {
            return this.getTime(params.data.TimeSpent);
          },
          cellClass: "text-center",
        },
        {
          headerName: "No. of articles forwarded",
          field: "ArticlesShared",
          filter: true,
          width: 240,
          valueGetter: (params) => {
            return params.data.ArticlesShared ? params.data.ArticlesShared : 0;
          },
          cellClass: "text-center",
        },
        {
          headerName: "No. of Likes",
          field: "ArticlesLiked",
          filter: true,
          width: 140,
          valueGetter: (params) => {
            return params.data.ArticlesLiked ? params.data.ArticlesLiked : 0;
          },
          cellClass: "text-center",
        },
        {
          headerName: "No. of Comments",
          field: "ArticlesCommented",
          filter: true,
          valueGetter: (params) => {
            return params.data.ArticlesCommented
              ? params.data.ArticlesCommented
              : 0;
          },
          width: 200,
          cellClass: "text-center",
        },
        {
          headerName: "User Channels",
          field: "UserGroups",
          filter: true,
          width: 200,
        },
        {
          headerName: "Date Added",
          field: "usersDetails_created_datetimestamp",
          filter: true,
          valueGetter: (params) => {
            return new Date(
              params.data.usersDetails_created_datetimestamp
            ).toLocaleString("en-GB");
          },
          width: 200,
        },
      ],
      tableData: [],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions,
      },
    };
  },
  watch: {
    roleFilter(obj) {
      this.setColumnFilter("role", obj.value);
    },
    statusFilter(obj) {
      this.setColumnFilter("status", obj.value);
    },
    isVerifiedFilter(obj) {
      const val =
        obj.value === "all" ? "all" : obj.value === "yes" ? "true" : "false";
      this.setColumnFilter("is_verified", val);
    },
    departmentFilter(obj) {
      this.setColumnFilter("department", obj.value);
    },
  },
  computed: {
    getTime() {
      return (time) => {
        return new Date(time * 1000).toISOString().substr(11, 8);
      };
    },
    usersData() {
      return this.tableData;
    },
    excelData() {
      return this.tableData.map((x) => {
        return {
          "Client Name": x.usersDetails_UserName,
          Mobile: x.usersDetails_Mobile,
          Email: x.usersDetails_Email,
          CRN: x.CRN ? x.CRN : null,
          "Whatsapp Update": x.whatsappContent ? x.whatsappContent : "No",
          "Kotak Customer": x.kotakCustomer ? x.kotakCustomer : "No",
          "Company Name": x.companyName ? x.companyName : null,
          "No. of articles sent": x.ArticlesSent ? x.ArticlesSent : 0,
          "No. of articles Read": x.ArticlesViewed ? x.ArticlesViewed : 0,
          "Total Time Spent": this.getTime(x.TimeSpent),
          "No. of articles forwarded": x.ArticlesShared ? x.ArticlesShared : 0,
          "No. of Likes": x.ArticlesLiked ? x.ArticlesLiked : 0,
          "No. of Comments": x.ArticlesCommented ? x.ArticlesCommented : 0,
          "User Channels": x.UserGroups,
          "Date Added": new Date(
            x.usersDetails_created_datetimestamp
          ).toLocaleString("en-GB"),
        };
      });
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    async getData() {
      this.$vs.loading();
      let payload = {
        communityName: currentCommunityName.communityName,
      };
      const { data } = await promotionsRepository.getReceipientWiseData(
        payload
      );
      if (data) {
        this.$vs.loading.close();
        this.tableData = data.data;
      }
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== "all") {
        modelObj = { type: "equals", filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null);
      this.gridApi.onFilterChanged();

      // Reset Filter Options
      this.roleFilter =
        this.statusFilter =
        this.isVerifiedFilter =
        this.departmentFilter =
          {
            label: "All",
            value: "all",
          };

      this.$refs.filterCard.removeRefreshAnimation();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
  },
  mounted() {
    this.getData();
    this.gridApi = this.gridOptions.api;

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left = `-${String(
        Number(header.style.transform.slice(11, -3)) + 9
      )}px`;
    }
  },
  created() {
    // if (!moduleUserManagement.isRegistered) {
    //   this.$store.registerModule("userManagement", moduleUserManagement);
    //   moduleUserManagement.isRegistered = true;
    // }
    // this.$store.dispatch("userManagement/fetchUsers").catch(err => {
    //   console.error(err);
    // });
  },
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

@media (max-width: 641px) {
  .padding-b {
    padding-bottom: 3.5rem !important;
  }
}
</style>
