<template>
  <div class="vx-card p-6">
    <div class="flex flex-wrap items-center">
      <!-- ITEMS PER PAGE -->
      <div class="flex-grow">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * paginationPageSize - (paginationPageSize - 1) }}
              -
              {{
                usersData.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : usersData.length
              }}
              of {{ usersData.length }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
              <span>25</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
              <span>30</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <ExportExcel :excelData="excelData"></ExportExcel>
    </div>

    <!-- AgGrid Table -->
    <ag-grid-vue
      ref="agGridTable"
      :components="components"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="usersData"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :floatingFilter="true"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :enableRtl="$vs.rtl"
    >
    </ag-grid-vue>

    <vs-pagination :total="totalPages" :max="7" v-model="currentPage" />
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/Client/Kotak/scss/agGridStyleOverride.scss";
import CellRendererLink from "../cell-renderer/CellRendererLink.vue";
import CellRendererStatus from "../cell-renderer/CellRendererStatus.vue";
import CellRendererVerified from "../cell-renderer/CellRendererVerified.vue";
import CellRendererActions from "../cell-renderer/CellRendererActions.vue";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const promotionsRepository = RepositoryFactory.get("promotions");
import ExportExcel from "@/Core/Views/CommonPlugins/export-excel/ExportExcel";
export default {
  props: {
    fromDate: {
      type: Date,
      default: "2000-01-01 00:00:00"
    },
    toDate: {
      type: Date,
      default: "2050-01-01 00:00:00"
    }
  },
  components: {
    AgGridVue,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    ExportExcel
  },
  data() {
    return {
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: "Client Name",
          field: "user_name",
          width: 300,
          filter: true
        },
        {
          headerName: "Mobile No.",
          field: "mobile_no",
          filter: true,
          width: 200,
          cellClass: "text-center"
        },
        {
          headerName: "Email",
          field: "email",
          filter: true,
          width: 200,
          cellClass: "text-center"
        },
        {
          headerName: "Date Added",
          field: "modified_date",
          filter: true,
          valueGetter: params => {
            return new Date(params.data.modified_date).toLocaleString("en-GB");
          },
          width: 200
        }
      ],
      tableData: [],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions
      }
    };
  },
  computed: {
    usersData() {
      return this.tableData;
    },
    excelData() {
      return this.tableData.map(x => {
        return {
          "Client Name": x.user_name,
          "Mobile No.": x.mobile_no,
          Email: x.email,
          "Date Added": new Date(x.modified_date).toLocaleString("en-GB")
        };
      });
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  methods: {
    async getData() {
      this.$vs.loading();
      let payload = {
        communityName: currentCommunityName.communityName,
        fromDate:
          this.fromDate == ""
            ? "2000-01-01 00:00:00"
            : new Date(this.fromDate)
                .toISOString()
                .replace("T", " ")
                .replace("Z", ""),
        toDate:
          this.toDate == ""
            ? "2050-01-01 00:00:00"
            : new Date(this.toDate)
                .toISOString()
                .replace("T", " ")
                .replace("Z", "")
      };
      const { data } = await promotionsRepository.getInvitedUsers(payload);
      if (data) {
        this.$vs.loading.close();
        this.tableData = data.data;
      }
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>
