<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="tab_card lg:m-10 m-2">
    <div class="vx-row">
      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 card_height"
        :class="{ card__active: show_Recipient }"
        @click="
          show_Recipient = true;
          show_Article = false;
          show_Range = false;
          show_Static = false;
        "
      >
        <statistics-card-line
          :statistic="''"
          icon="UsersIcon"
          statisticTitle="Recipient wise"
          type="area"
        />
      </div>

      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 card_height"
        :class="{ card__active: show_Article }"
        @click="
          show_Recipient = false;
          show_Article = true;
          show_Range = false;
          show_Static = false;
        "
      >
        <statistics-card-line
          :statistic="''"
          icon="FileTextIcon"
          statisticTitle="Article wise"
          color="success"
          type="area"
        />
      </div>

      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 card_height"
        :class="{ card__active: show_Range }"
        @click="
          show_Recipient = false;
          show_Article = false;
          show_Range = true;
          show_Static = false;
        "
      >
        <statistics-card-line
          :statistic="''"
          icon="ClockIcon"
          statisticTitle=" Time Range"
          color="danger"
          type="area"
        />
      </div>
      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 card_height"
        :class="{ card__active: show_Static }"
        @click="
          show_Recipient = false;
          show_Article = false;
          show_Range = false;
          show_Static = true;
        "
      >
        <statistics-card-line
          :statistic="''"
          icon="ActivityIcon"
          statisticTitle="Static Info"
          color="warning"
          type="area"
        />
      </div>
    </div>

    <!-- Recipient wise table-->
    <UserList v-if="show_Recipient"></UserList>

    <!-- Article wise table-->
    <userlist2 v-if="show_Article"></userlist2>

    <!--Time Range-->
    <userlist3 v-if="show_Range"></userlist3>

    <!-- Static Info table-->
    <userlist4 v-if="show_Static"></userlist4>
  </div>
</template>

<script>
import UserList from "./UserList";
import userlist2 from "./userlist2";
import userlist3 from "./userlist3";
import userlist4 from "./userlist4";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import StatisticsCardLine from "./StatisticsCardLine.vue";
import analyticsData from "./analyticsData.js";
import ChangeTimeDurationDropdown from "./ChangeTimeDurationDropdown.vue";

export default {
  components: {
    StatisticsCardLine,
    VuePerfectScrollbar,
    ChangeTimeDurationDropdown,
    UserList,
    userlist2,
    userlist3,
    userlist4,
  },
  data() {
    return {
      show_Recipient: true,
      show_Article: false,
      show_Range: false,
      show_Static: false,
      date: null,
      subscribersGained: {},
      revenueGenerated: {},
      quarterlySales: {},
      ordersRecevied: {},

      revenueComparisonLine: {},
      goalOverview: {},

      browserStatistics: [],
      clientRetentionBar: {},

      sessionsData: {},
      chatLog: [],
      chatMsgInput: "",
      customersData: {},

      analyticsData,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  computed: {
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
  mounted() {},
  created() {},
};
</script>

<style lang="scss">
.chat-card-log {
  height: 400px;

  .chat-sent-msg {
    background-color: #f2f4f7 !important;
  }
}
</style>
<style>
.card_height > .vx-card {
  height: 75%;
  cursor: pointer;
}
.table_height {
  position: relative;
  bottom: 12px;
}
.vs-table--thead {
  white-space: nowrap !important;
}
.vx-card {
  border: 2px solid #fff;
}

.card__active > .vx-card {
  border: 3px solid #8f86f3;
  color: #fff !important;
}
.stat_active > .vx-card {
  border: 3px solid rgba(var(--vs-success), 1);
  color: #fff !important;
}
.table_height > .vs-table--content {
  border-radius: 0.5rem !important;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
.date_grp {
  display: flex;
  justify-content: flex-end;
}
.datepicker_class > div > input {
  background: #fff !important;
}
</style>
